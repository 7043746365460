<template>
    <div>
        <!-- formData.type == 2 图片组件-->
        <div>
            <div class="style_box">
                <div>
                    <div class="title_box">
                        <div class="title_text">
                            <Icon
                                style="font-size: 20px"
                                type="ios-arrow-down"
                            />图片样式
                        </div>
                    </div>
                    <div class="grid">
                        <div
                            v-for="(item, index) in grid_items"
                            :key="index"
                            @click="toggleItem(index, item.num)"
                            :class="[
                                { active: formData.imgtype === index },
                                'default',
                            ]"
                        >
                            <img
                                @dragstart.prevent
                                :src="
                                    index == formData.imgtype
                                        ? item.activeImageSrc
                                        : item.imageSrc
                                "
                                :alt="item.altText"
                                :width="item.width"
                                :height="item.height"
                            />
                            <div
                                :class="{ activeP: formData.imgtype === index }"
                                style="user-select: none"
                            >
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                    <div class="title_box">
                        <div class="title_text">
                            <Icon
                                style="font-size: 20px"
                                type="ios-arrow-down"
                            />图片编辑
                        </div>
                    </div>

                    <div class="banner_box">
                        <Upload
              ref="upload"
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'png', 'gif']"
              :max-size="2048"
              :headers="header"
              :on-format-error="handleFormatError"
              :on-success="picImgSuccess"
              :on-progress="handleProgress"
              :action="uploads"
            >
                        <div class="absolute_div">
                            <Icon class="absolute_icon" type="md-add" />
                        </div>
                        <Icon
                            type="md-close-circle"
                            color="black"
                            size="20"
                            class="icon"
                            @click.stop="handleRemove()"
                        />
                        </Upload>

                        <template
                            v-if="
                                formData.content.imgs.length - 1 >=
                                this.formData.imgkey
                            "
                        >
                            <img
                                style="object-fit: cover"
                                :src="
                                    formData.content.imgs[this.formData.imgkey]
                                        .picview
                                "
                                alt=""
                            />
                        </template>
                        <template v-else>
                            <img
                                style="object-fit: cover"
                                :src="formData.content.imgs[0].picview"
                                alt=""
                            />
                        </template>
                    </div>
                    <div>
                        <Form label-position="right" :label-width="70">
                            <FormItem
                                style="margin: 0 0 10px 0"
                                label="图片尺寸"
                            >
                                <div style="margin-bottom: 5px" class="just_sb">
                                    <div class="display_flex">
                                        <div class="kuai_box">高</div>
                                        <InputNumber
                                            :max="500"
                                            :min="0"
                                            :precision="0"
                                            v-model="formData.content.height"
                                            style="width: 120px"
                                        ></InputNumber>
                                    </div>
                                </div>
                            </FormItem>
                            <!-- 内边距 -->
                            <!-- <FormItem style="margin: 0 0 10px 0" label="内边距">
                <div style="margin-bottom: 5px" class="just_sb">
                  <div class="display_flex">
                    <div class="kuai_box">上</div>
                    <Input
                      v-model="formData.content.paddingData.top"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                  <div class="display_flex">
                    <div class="kuai_box">下</div>
                    <Input
                      v-model="formData.content.paddingData.bottom"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                </div>
                <div class="just_sb">
                  <div class="display_flex">
                    <div class="kuai_box">左</div>
                    <Input
                      v-model="formData.content.paddingData.left"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                  <div class="display_flex">
                    <div class="kuai_box">右</div>
                    <Input
                      v-model="formData.content.paddingData.right"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                </div>
              </FormItem> -->
                            <!-- 背景色 -->
                            <!-- <FormItem style="margin: 0 0 10px 0" label="背景色">
                <ColorPicker
                  style="width: 150px"
                  v-model="formData.content.bgColor"
                />
              </FormItem> -->
                            <slot></slot>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config";
import { getCookies } from "@/utils/helper";
export default {
    props: {
        formData: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            header: { "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token") },
            uploads: config.apiUrl + "/storeapi/data/uploads",
            new_data: {},
            grid_items: [
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_0.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_0_active.png",
                    altText: "图片1",
                    text: "1张图",
                    width: "54px",
                    height: "54px",
                    num: 1,
                },
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_1.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_1_active.png",
                    altText: "图片2",
                    text: "2张图",
                    width: "54px",
                    height: "54px",
                    num: 2,
                },
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_2.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_2_active.png",
                    altText: "图片3",
                    text: "3张图",
                    width: "54px",
                    height: "54px",
                    num: 3,
                },
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_3.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_3_active.png",
                    altText: "图片4",
                    text: "4张图",
                    width: "54px",
                    height: "54px",
                    num: 4,
                },
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_4.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_4_active.png",
                    altText: "图片5",
                    text: "2张图平分",
                    width: "59px",
                    height: "54px",
                    num: 2,
                },
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_5.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_5_active.png",
                    altText: "图片6",
                    text: "3张图平分",
                    width: "67px",
                    height: "54px",
                    num: 3,
                },
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_6.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_6_active.png",
                    altText: "图片7",
                    text: "4张图左右平分",
                    width: "78px",
                    height: "47px",
                    num: 4,
                },
                {
                    imageSrc: config.apiUrl + "/img/grid/rubik_7.png",
                    activeImageSrc:
                        config.apiUrl + "/img/grid/rubik_7_active.png",
                    altText: "图片8",
                    text: "4张图上下平分",
                    width: "59px",
                    height: "54px",
                    num: 4,
                },
            ],
        };
    },
    watch: {
        formData: {
            handler(n_data, o_data) {
                this.new_data = n_data;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        toggleItem(index, num) {
            this.$emit("update:modifyHot", "划分热区");
            this.formData.imgkey = 0;
            this.new_data.content.hotspot = [];
            this.new_data.imgtype = index;
            this.new_data.content.imgs = [];
            for (let i = 0; i < num; i++) {
                this.new_data.content.imgs.push({
                    picview: "http://canyin.hulianzaowu.top/img/diy/goods.png",
                    value: "/img/diy/goods.png",
                });
            }
            this.$emit("update:formData", this.new_data);
        },
        picImgSuccess(e) {
          this.new_data.content.imgs[this.formData.imgkey].picview = e.data.path;
          this.new_data.content.imgs[this.formData.imgkey].value = e.data.src;
          this.$emit("update:formData", this.new_data);
          this.$Message.destroy();
          this.$Message.success(e.msg);
          this.isTure = true;
        },
        // //文件上传类型错误
        handleFormatError() {
          this.$Message.warning("暂不支持上传此格式");
        },
        // // 文件上传时
        handleProgress() {
          const msg = this.$Message.loading({
            content: "上传中...",
            duration: 0,
          });
          setTimeout(msg, 3000);
        },
        handleRemove() {
            this.new_data.content.imgs[this.new_data.imgkey].picview =
                "http://canyin.hulianzaowu.top/img/diy/goods.png";
            this.$emit("update:formData", this.new_data);
        },
    },
};
</script>

<style scoped>
.grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.default {
    width: 20%;
    margin: 10px;
    border: 1px solid #eee;
    text-align: center;
}

.active {
    border: 1px solid #409eff;
}
.activeP {
    color: #409eff;
}
.style_box {
    padding: 0 10px;
}

.title_box {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}

.title_text {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}
.banner_box {
    width: 100%;
    height: 130px;
    display: flex;
    margin: 10px 0;
    justify-content: center;
    position: relative;
}

.banner_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.absolute_div {
    position: absolute;
    width: 100%;
    height: 130px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.absolute_icon {
    position: absolute;
    font-size: 50px;
    cursor: pointer;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* display: block; */
}
.icon {
    position: absolute;
    top: -10px;
    right: -10px;
}
.just_sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.kuai_box {
    width: 30px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #bfbfbf;
}

.display_flex {
    display: flex;
}
</style>