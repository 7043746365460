<template>
  <div class="son-page">
    <div @click="closeModal" class="back_box">
      <div class="pointer">
        <Icon style="color: #515a6e" size="24" type="ios-arrow-back" />
      </div>
      <div class="ml10">{{ modalData.title }}</div>
    </div>
    <div class="content_box">
      <Tabs value="1" @on-click="changeTab">
        <TabPane label="商品详情" name="1"></TabPane>
        <TabPane label="商品图" name="2"></TabPane>
      </Tabs>
      <div v-if="index == '1'">
        <Form label-position="left" :label-width="100">
          <FormItem label="商品名：">
            <Input
              v-model="formData.goods_name"
              style="width: 300px"
              placeholder="请输入商品名字"
            ></Input>
          </FormItem>
          <FormItem label="商品图：">
            <div style="display: flex">
              <div>
                <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :headers="header"
                    :on-format-error="handleFormatError"
                    :on-success="uploadImgSuccess"
                    :on-progress="handleProgress"
                    multiple
                    :action="uploads">
                <div class="uplads_box">
                  <div style="text-align: center">
                    <div style="margin-top: -10px">
                      <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                    </div>
                    <div
                      class="font14"
                      style="margin-top: -10px; color: #9ca7b9"
                    >
                      点击上传
                    </div>
                    <div
                      style="font-size: 12px; color: #9ca7b9; margin-top: -15px"
                    >
                      （375*812）
                    </div>
                  </div>
                </div>
                </Upload>
              </div>
              <div v-if="picview" class="img_view_Box">
                <img :src="picview" alt="" />
              </div>
            </div>
          </FormItem>
          <FormItem label="所需积分：">
            <Input
              v-model="formData.num"
              style="width: 300px"
              placeholder="请输入所需积分"
            ></Input>
          </FormItem>
          <FormItem label="提取方式：">
            <CheckboxGroup v-model="formData.get_type">
              <Checkbox :label="1">自提</Checkbox>
              <Checkbox :label="2" style="margin-left: 40px">快递</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="兑换限制：">
            <RadioGroup v-model="formData.exchange_restrict">
              <Radio :label="1">无限制</Radio>
              <Radio class="ml10" :label="2">每人只可兑换一次</Radio>
              <Radio class="ml10" :label="3">每人每天可兑换一个</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="库存配置：">
            <i-switch
              size="large"
              v-model="formData.stock_type"
              :true-value="1"
              :false-value="0"
            >
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>
          </FormItem>
          <FormItem v-if="formData.stock_type == 1" label="库存数量：">
            <Input
              v-model="formData.stock_num"
              style="width: 300px"
              placeholder="请输入库存数量"
            ></Input>
          </FormItem>
          <FormItem label="商品详情：">
            <!-- <textarea v-model="formData.desc" class="textarea_style"></textarea> -->
            <div id="websiteEditorElem" style="background: #ffffff"></div>
          </FormItem>
        </Form>
      </div>
      <div v-if="index == '2'">
        <div class="top-btns">
          <div class="title">
            本地上传<span style="color: #999; font-size: 12px"
              >（415*300）</span
            >
          </div>
          <div v-if="viewPic.length != 0" @click="adduplods" class="l-btn">
            确认上传
          </div>
        </div>
        <div class="update-pic">
          <div v-for="(item, index) of viewPic" :key="index" class="image-list">
            <img :src="item" alt="" />
          </div>
          <div class="image-list">
            <Upload
              ref="upload"
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'png']"
              :max-size="2048"
              :headers="header"
              :on-format-error="handleFormatError"
              :on-success="uploadImgSuccessList"
              :on-progress="handleProgress"
              multiple
              type="drag"
              :action="uploads"
              style="display: inline-block; width: 100%; height: 180px"
            >
              <div
                style="
                  width: 100%;
                  height: 180px;
                  line-height: 180px;
                "
              >
                <Icon type="md-add" size="50" />
              </div>
            </Upload>
          </div>
        </div>
        <div class="already">
          <div class="title">banner图片</div>
          <div class="update-pic">
            <draggable
                class="syllable_ul"
                element="ul"
                :list="formData.img_arr"
                :options="{group:'title', animation:150}"
                :no-transition-on-drag="true"
                @change="change"
                @start="start"
                @end="end"
                :move="move"
            >
                <transition-group style="display: flex;align-items: center;flex-wrap: wrap;" type="transition"  :name="!drag? 'syll_li' : null" :css="true">
                <div v-for="(item,index) of formData.img_arr" :key="index + 'S'" class="image-list">
                    <Icon @click="close_Btn(index)" class="colse_DW" type="ios-close-circle" />
                    <img :src="config.apiUrl+'/'+item" alt="">
                    <div style="text-align:center">{{index+1}}</div>
                </div>
                </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_btn">
      <Button @click="commitBtn" class="btn">保存</Button>
    </div>
  </div>
</template>

<script>
import { addGoods, getIntegralDetails, updateGoods } from "@/api/setting";
import E from "wangeditor";
import { getCookies } from "@/utils/helper";
import config from "@/config";
import draggable from "vuedraggable";
var editor2;
export default {
  props: { modalData: Object },
  components: { draggable, },
  data() {
    return {
        header: {
          "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token"),
        },
        uploads: config.apiUrl + "/storeapi/data/uploads",
      index: "1",
      picview: "",
      phoneEditor: "",
      formData: {
        goods_name: "",
        goods_img: "",
        num: "",
        get_type: [],
        exchange_restrict: "",
        stock_type: 0,
        stock_num: "",
        desc: "",
        img_arr: [],
      },
      viewPic: [],
      images: [],
      imgList: [],
      drag: false,
    };
  },
  mounted() {
    editor2 = new E("#websiteEditorElem");
    editor2.config.uploadImgServer = this.uploads;
    editor2.config.uploadFileName = "file";
    editor2.config.uploadImgHeaders = {
      "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token"),
    };
    editor2.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        var url = result.data.path;
        insertImgFn(url);
      },
    };
    editor2.create();
  },
  created() {
    if (this.modalData.title == "编辑商品") {
      getIntegralDetails({ id: this.modalData.id }).then((res) => {
        console.log("9999999", res);
        this.picview = res.data.goods_img_domain;
        this.formData.goods_img = res.data.goods_img;
        this.formData.img_arr = res.data.img_arr;
        this.formData.goods_name = res.data.goods_name;
        this.formData.num = res.data.num;
        this.formData.get_type = res.data.get_type;
        this.formData.exchange_restrict = res.data.exchange_restrict;
        this.formData.stock_type = res.data.stock_type;
        this.formData.stock_num = res.data.stock_num;
        editor2.txt.html(res.data.desc);
      });
    }
  },
  methods: {
    changeTab(e) {
      this.index = e;
    },
    closeModal() {
      this.$emit("closeModal", "close");
    },
    commitBtn() {
      this.formData.desc = editor2.txt.html();
      if (this.modalData.title == "编辑商品") {
        if (this.formData.goods_img.indexOf("https") >= 0) {
          const value2 = this.formData.goods_img.slice(41);
          this.formData.goods_img = value2;
          let number = this.formData.img_arr.map((name) =>
            name.replace("https://oss.hulianzaowu.top/api/img/show/", "")
          );
          this.formData.img_arr = number;
        }
        if (this.formData.goods_img.indexOf("http") >= 0) {
          const value1 = this.formData.goods_img.slice(39);
          this.formData.goods_img = value1;
          let numbers = this.formData.img_arr.map((name) =>
            name.replace("http://192.168.1.199:9091/api/img/show/", "")
          );
          this.formData.img_arr = numbers;
        }
        this.formData.id = this.modalData.id;
        updateGoods(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.$emit("closeModal", "add");
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else {
        addGoods(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.$emit("closeModal", "add");
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      }
    },
    adduplods() {
      console.log(this.viewPic, this.images);
      this.viewPic.forEach((item) => {
        this.imgList.push(item);
      });
      this.images.forEach((item) => {
        this.formData.img_arr.push(item);
      });
      this.viewPic = [];
      this.images = [];
    },
    close_Btn(e) {
      this.imgList.splice(e, 1);
      this.formData.img_arr.splice(e, 1);
    },
    move() {},
    change() {},
    start() {
      this.drag = true;
    },
    end(evt) {
      this.drag = true;
      evt.item; //可以知道拖动的本身
      evt.to; // 可以知道拖动的目标列表
      evt.from; // 可以知道之前的列表
      evt.oldIndex; // 可以知道拖动前的位置
      evt.newIndex; // 可以知道拖动后的位置
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e) {
      this.picview = e.data.path;
      this.formData.goods_img = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    uploadImgSuccessList(e) {
      this.viewPic.push(e.data.path);
      this.images.push(e.data.src);
      this.$Message.destroy();
      // this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
  },
};
</script>

<style scoped>
.son-page {
  position: relative;
}
.back_box {
  width: 100%;
  cursor: pointer;
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
}
.content_box {
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-top: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
}
.bottom_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 100px;
  border-top: 1px solid #eee;
  background: #fff;
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
.img_view_Box {
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-left: 20px;
  overflow: hidden;
}
.img_view_Box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.textarea_style {
  outline: none;
  line-height: 19px;
  border-color: #dcdee2;
  max-width: 300px;
  min-width: 300px;
  max-height: 80px;
  min-height: 80px;
}

.top-btns {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
}
.ivu-icon {
  color: #ff5c11;
}
.l-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  background: #ff761d;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 15px;
  margin-right: 20px;
  margin-left: 20px;
}
.colse_DW {
  position: absolute;
  color: #999 !important;
  font-size: 30px;
  top: -14px;
  right: -14px;
  z-index: 99;
}

.r-set {
  color: #585858;
  border: 1px solid #cccccc;
  background: white;
}

.update-pic {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.image-list {
  border: 1px solid #eee;
  position: relative;
  width: 245px;
  height: 180px;
  /* background: #555555; */
  border-radius: 4px;
  margin-bottom: 25px;
  margin-left: 20px;
}
.image-list img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.already .title {
  font-size: 18px;
  font-weight: bold;
  color: #343434;
  margin-left: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.already .title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 17px;
  background: #ff5c11;
  border-radius: 3px;
  margin-right: 10px;
}
.top-btns .title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 17px;
  background: #ff5c11;
  border-radius: 3px;
  margin-right: 10px;
}
.top-btns .title {
  font-size: 18px;
  font-weight: bold;
  color: #343434;
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
  /* margin-bottom: 30px; */
}
</style>
<style lang="less" scoped>
/deep/ .ivu-modal-mask {
  z-index: 10006 !important;
}

/deep/ .ivu-modal-wrap {
  z-index: 10006 !important;
}
/deep/ .w-e-toolbar {
  z-index: 999 !important;
}
/deep/ .w-e-text-container {
  z-index: 999 !important;
}
</style>