<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div>
        <Icon size="24" type="ios-arrow-back" />
      </div>
      <div class="ml10">天降红包</div>
    </div>
    <div class="content_box">
      <div>
        <Button @click="cardModalBtn('add')" class="btn">创建</Button>
      </div>
      <Table stripe style="margin-top: 20px" :columns="columns12" :data="data6">
        <template slot-scope="{ index }" slot="index">
          <strong>{{ index + 1 }}</strong>
        </template>
        <template slot-scope="{ row }" slot="type">
          <div>
            {{
              row.page_type === 1
                ? "首页"
                : row.page_type === 2
                ? "外卖自取点单页"
                : row.page_type === 3
                ? "堂食点单页"
                : ""
            }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="packet">
          <div>
            {{
              row.packet_give === 1
                ? "赠送成长值"
                : row.packet_give === 2
                ? "赠送卡券"
                : ""
            }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="join">
          <div v-for="(item, index) in row.joins" :key="index">
            {{
              item === "1"
                ? "新客"
                : item === "2"
                ? "会员"
                : item === "3"
                ? "用户"
                : ""
            }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <Button
            type="success"
            ghost
            size="small"
            v-if="row.status == 0"
            @click="push(row)"
            style="margin-right: 5px"
            >启用</Button
          >
          <!--          <Button type="success" ghost size="small" v-if="row.status==2" @click="push(row)" style="margin-right: 5px">发布</Button>-->
          <Button
            type="warning"
            ghost
            size="small"
            v-if="row.status == 1"
            @click="push(row)"
            style="margin-right: 5px"
            >停止</Button
          >
          <Button
            type="primary"
            @click="cardEdit(row)"
            v-if="row.status != 2"
            ghost
            size="small"
            style="
              margin-right: 5px;
              color: #2d8cf0;
              border: 1px solid #2d8cf0;
              background: none;
            "
            >编辑</Button
          >
          <Button type="error" ghost size="small" @click="removeIt(row)"
            >删除</Button
          >
        </template>
      </Table>
      <br />
      <Page
        :total="total"
        :current="storePrams.page"
        style="text-align: right"
        show-elevator
        show-total
        @on-change="pageChange"
        :page-size="storePrams.limit"
      />
    </div>
    <Modal
      v-model="cardModal"
      :title="cardtitle"
      width="600"
      @on-cancel="cardCancel"
    >
      <Form style="width: 450px" label-position="left" :label-width="120">
        <FormItem label="活动名称：">
          <Input
            placeholder="请输入活动名称"
            v-model="formData.activity_title"
          ></Input>
        </FormItem>
        <FormItem label="首页活动封面：">
          <div style="display: flex">
            <div>
              <Upload
                  ref="upload"
                  :show-upload-list="false"
                  :format="['jpg','jpeg','png']"
                  :max-size="2048"
                  :headers="header"
                  :on-format-error="handleFormatError"
                  :on-success="uploadImgSuccess"
                  :on-progress="handleProgress"
                  multiple
                  :action="uploads">
              <div class="uplads_box">
                <div style="text-align: center">
                  <div style="margin-top: -10px">
                    <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                  </div>
                  <div class="font14" style="margin-top: -10px; color: #9ca7b9">
                    点击上传
                  </div>
                </div>
              </div>
              </Upload>
            </div>
            <div class="upPic" v-if="activityPic">
              <img :src="activityPic" alt="" />
            </div>
          </div>
        </FormItem>
        <FormItem label="显示场景：">
          <Select v-model="formData.page_type">
            <Option
              v-for="item in cityList"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="参与人群：">
          <CheckboxGroup v-model="formData.joins">
            <Checkbox label="1">新客</Checkbox>
            <Checkbox label="2">会员</Checkbox>
            <Checkbox label="3">普通用户</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="红包类型：">
          <Select v-model="formData.packet_give">
            <Option
              v-for="item in cityList1"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="卡券：" v-if="formData.packet_give == 2">
          <CheckboxGroup v-model="formData.coupon_ids">
            <div
              class="chooseeMore"
              v-for="(item, index) in cityList3"
              :key="index"
            >
              <Checkbox :label="item.id">{{ item.coupon_name }}</Checkbox>
            </div>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="成长值数量：" v-if="formData.packet_give == 1">
          <Input
            placeholder="请输入成长值数量"
            v-model="formData.give_val"
          ></Input>
        </FormItem>
        <FormItem label="选择模板：">
          <Select v-model="formData.template_id">
            <Option v-for="item in cityList4" :value="item.id" :key="item.id">{{
              item.template_name
            }}</Option>
          </Select>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="cardCancel">取消</Button>
        <Button class="btn" @click="cardadd">保存</Button>
      </div>
    </Modal>
    <Modal v-model="mubanModal" title="模板选择" @on-cancel="mubancancel">
      <div class="muban_box">
        <div class="item_muban_box">
          <div class="view_box"><img src="@/assets/3.jpg" alt="" /></div>
          <div class="check_btns">
            <Button @click="xuanze(1)" class="btn">{{
              formData.mubanIndex == 1 ? "已选择" : "选择"
            }}</Button>
          </div>
        </div>
        <!-- <div class="item_muban_box">
            <div class="view_box"><img src="@/assets/3.jpg" alt=""></div>
            <div class="check_btns"><Button @click="xuanze(2)" class="btn">{{formData.mubanIndex==2?'已选择':'选择'}}</Button></div>
        </div> -->
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import {
  getCoupon,
  packetAdd,
  getPacketTemp,
  packetList,
  packetDel,
  packetHandle,
  packetDetails,
  packetUpdate,
} from "../../../../api/elseSetting";
import config from "@/config";
import { getCookies } from "@/utils/helper";
export default {
  name: "password",
  data() {
    return {
      cardtitle: "创建卡卷",
      header: {
        "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token"),
      },
      uploads: config.apiUrl + "/storeapi/data/uploads",
      cardModal: false,
      mubanModal: false,
      total: 0,
      storePrams: {
        page: 1,
        limit: 10,
      },
      formData: {
        activity_title: "",
        activity_img: "",
        joins: [],
        page_type: "",
        packet_give: "",
        give_val: 0,
        coupon_ids: [],
        template_id: "",
      },
      cityList: [
        {
          value: "1",
          label: "首页",
        },
        {
          value: "2",
          label: "外卖自取点单页",
        },
        {
          value: "3",
          label: "堂食点单页",
        },
      ],
      cityList1: [
        {
          value: "2",
          label: "卡卷",
        },
        {
          value: "1",
          label: "成长值",
        },
      ],
      cityList3: [],
      cityList4: [],
      columns12: [
        {
          title: "#",
          slot: "index",
          width: 60,
          align: "center",
        },
        {
          title: "活动名称",
          key: "activity_title",
          align: "center",
        },
        {
          title: "使用场景",
          slot: "type",
          align: "center",
        },
        {
          title: "参与人群",
          key: "joins",
          slot: "join",
          align: "center",
        },
        {
          title: "红包类型",
          key: "packet_give",
          slot: "packet",
          align: "center",
        },
        {
          title: "红包内容",
          key: "val",
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          align: "center",
        },
        {
          title: "启用时间",
          key: "publish_time",
          align: "center",
        },
        {
          title: "停止时间",
          key: "stop_time",
          align: "center",
        },
        {
          title: "操作",
          width: 200,
          fixed: "right",
          align: "center",
          slot: "action",
        },
      ],
      data6: [],
      activityPic: "",
    };
  },
  created() {
    this.getCard();
    this.getList();
    this.getTemplate();
  },
  methods: {
    getCard() {
      getCoupon().then((res) => {
        this.cityList3 = res.data || [];
      });
    },
    getList() {
      packetList(this.storePrams).then((res) => {
        console.log(res);
        this.data6 = res.data.data || [];
        this.total = res.data.total;
      });
    },
    cardEdit(row) {
      packetDetails({ id: row.id }).then((res) => {
        this.cardtitle = "编辑活动";
        this.cardModal = true;
        this.activityPic = res.data.activity_img_domain;
        this.formData = {
          activity_title: res.data.activity_title,
          activity_img: res.data.activity_img,
          template_id: res.data.template_id,
          id: res.data.id,
          coupon_ids: res.data.coupon_ids,
          packet_give: res.data.packet_give ? String(res.data.packet_give) : "",
          joins: res.data.joins,
          give_val: res.data.give_val,
          page_type: res.data.page_type ? String(res.data.page_type) : "",
        };
      });
    },
    clearform() {
      this.activityPic = "";
      this.formData = {
        activity_title: "",
        activity_img: "",
        joins: [],
        page_type: "",
        packet_give: "",
        give_val: 0,
        coupon_ids: [],
        template_id: "",
      };
    },
    getTemplate() {
      getPacketTemp().then((res) => {
        this.cityList4 = res.data || [];
      });
    },
    push(e) {
      let status = 0,
        msg = "请问您是否确认启用活动，确定无误后点击确认发布。";
      if (e.status == 0) {
        status = 1;
      } else if (e.status == 1) {
        status = 2;
        msg = "请问您是否确认停止活动，确定无误后点击确认停止。";
      } else if (e.status == 2) {
        status = 1;
      }
      this.$Modal.confirm({
        title: "发布提示",
        content: msg,
        onOk: () => {
          packetHandle({ id: e.id, status: status })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((res) => {
              this.$Message.error(res.msg);
            });
        },
        onCancel: () => {},
      });
    },
    removeIt(row) {
      this.$Modal.confirm({
        title: "删除提示",
        content: "请问您是否确认删除此活动，删除后您将无法恢复！！！",
        onOk: () => {
          packetDel({ id: row.id })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((res) => {
              this.$Message.error(res.msg);
            });
        },
        onCancel: () => {},
      });
    },
    cardModalBtn(e) {
      if (e == "add") {
        this.cardtitle = "新建活动";
        this.cardModal = true;
      } else {
        this.cardtitle = "编辑活动";
        this.cardModal = true;
      }
    },
    cardCancel() {
      this.cardModal = false;
      this.clearform();
    },
    cardadd() {
      if (this.cardtitle == "新建活动") {
        packetAdd(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.cardModal = false;
            this.clearform();
            this.getList();
          })
          .catch((res) => {
            this.$Message.error(res.msg);
          });
      } else {
        packetUpdate(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.cardModal = false;
            this.clearform();
            this.getList();
          })
          .catch((res) => {
            this.$Message.error(res.msg);
          });
      }
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e) {
      this.activityPic = e.data.path;
      this.formData.activity_img = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    xuanze(e) {
      this.formData.mubanIndex = e;
      this.mubanModal = false;
    },
    mubancancel() {
      this.mubanModal = false;
    },
    mubanBtn() {
      this.mubanModal = true;
    },
    pageChange(index) {
      this.storePrams.page = index;
      this.getList();
    },
    backBtn() {
      this.$emit("backview", 0);
    },
  },
};
</script>

<style scoped>
/* .son-page {

} */

.back_box {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 30px;
}

.content_box {
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-top: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
}

.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}

.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}

.muban_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view_box {
  width: 200px;
  height: 270px;
  /* background: red; */
}

.view_box img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.item_muban_box {
  width: 200px;
  height: 320px;
  /* background: #000; */
}

.check_btns {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.upPic {
  margin-left: 20px;
  width: 105px;
  height: 105px;
  position: relative;
}

.upPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
</style>
