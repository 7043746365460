<template>
  <div>
    <div v-if="assembly">
      <Shopdecoration @backview="backview"></Shopdecoration>
    </div>
    <div v-else>
      <!-- 返回头 -->
      <div class="back_box pointer" @click="backBtn">
        <div>
          <Icon size="24" type="ios-arrow-back" />
        </div>
        <div class="ml10">店铺装修</div>
      </div>
      <!-- 主题内容 -->
      <div class="content_box">
        <Form class="formContent" label-position="right" :label-width="160">
          <FormItem label="小程序模式：">
            <Select v-model="formData.mini_mode" style="width: 200px">
              <Option
                v-for="item in patternList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
          <span v-if="formData.mini_mode == 1">
            <FormItem label="首页样式选择：">
              <RadioGroup
                :true-value="1"
                :false-value="0"
                v-model="formData.home_mode"
              >
                <Radio :label="1">选择首页样式模板</Radio>
                <Radio v-if="diy_plugin" style="margin-left: 20px" :label="2"
                  >DIY首页样式</Radio
                >
              </RadioGroup>
            </FormItem>
            <FormItem v-if="formData.home_mode == 1" label="选择模板：">
              <Select
                v-model="formData.applet_template_id"
                style="width: 200px"
              >
                <Option
                  v-for="item in classdata"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
              <div
                v-if="classdata[formData.applet_template_id - 1]"
                class="class_img_box"
              >
                <img
                  :src="classdata[formData.applet_template_id - 1].pic"
                  alt=""
                />
                <div
                  @click="
                    editdivBtn(classdata[formData.applet_template_id - 1])
                  "
                  class="view_box"
                >
                  <Icon style="color: #fff" size="100" type="ios-cog-outline" />
                </div>
              </div>
            </FormItem>
            <FormItem v-else label="">
              <PhoneView
                v-if="RefreshStatus"
                @linkDIY="linkDIY"
                :style="'background:' + bgColor"
                :myArray="myArray"
              ></PhoneView>
            </FormItem>
          </span>
          <span v-else>
            <FormItem label="">
              <div class="class_img_box">
                <img :src="templateLsPic" alt="" />
              </div>
            </FormItem>
          </span>
          <div class="bottom_box">
            <Button @click="subBtn" class="btn">保存</Button>
          </div>
        </Form>
      </div>

      <!-- 编辑模板弹框 -->
      <Modal
        :mask-closable="false"
        v-model="Divmodal"
        @on-cancel="divcancel"
        title="编辑模板"
      >
        <div class="model_box">
          <Form label-position="left" :label-width="110">
            <div class="menu_text">公告设置</div>
            <FormItem label="公告背景：">
              <ColorPicker v-model="formData.news_bg_color" />
            </FormItem>
            <FormItem label="公告字体颜色：">
              <ColorPicker v-model="formData.news_font_color" />
            </FormItem>
            <FormItem label="公告内容：">
              <textarea
                class="textar"
                v-model="formData.content"
                placeholder="请输入公告内容"
              ></textarea>
            </FormItem>
            <span
              v-if="
                classdata[formData.applet_template_id - 1] &&
                classdata[formData.applet_template_id - 1].id == 3
              "
            >
              <div class="menu_text">模板设置</div>

              <FormItem label="背景色：">
                <ColorPicker v-model="template_div.bgColor" />
              </FormItem>

              <div class="menu_text">菜单一</div>
              <FormItem label="背景图：">
                <div style="display: flex">
                  <div>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg','jpeg','png']"
                        :max-size="2048"
                        :headers="header"
                        :on-format-error="handleFormatError"
                        :on-success="uploadImgSuccessdiv1"
                        :on-progress="handleProgress"
                        multiple
                        :action="uploads">
                    <div class="uplads_box">
                      <div style="text-align: center">
                        <div style="margin-top: -10px">
                          <Icon
                            style="color: #9ea6b9"
                            size="70"
                            type="ios-add"
                          />
                        </div>
                        <div
                          class="font14"
                          style="margin-top: -10px; color: #9ca7b9"
                        >
                          点击上传
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            color: #9ca7b9;
                            margin-top: -15px;
                          "
                        >
                          （342*90）
                        </div>
                      </div>
                    </div>
                    </Upload>
                  </div>
                  <div v-if="template_div.menu1.bgImg" class="img_view_Box">
                    <img :src="template_div.menu1.bgImg" alt="" />
                  </div>
                </div>
              </FormItem>
              <FormItem label="文字颜色：">
                <ColorPicker v-model="template_div.menu1.textColor" />
              </FormItem>
              <FormItem label="跳转路径：">
                <Select
                  v-model="template_div.menu1.pathValue"
                  style="width: 200px"
                >
                  <Option
                    v-for="item in pathList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="主标题：">
                <Input
                  v-model="template_div.menu1.text1"
                  placeholder="请输入带单文字一"
                ></Input>
              </FormItem>
              <FormItem label="副标题：">
                <Input
                  v-model="template_div.menu1.text2"
                  placeholder="请输入带单文字二"
                ></Input>
              </FormItem>

              <div class="menu_text">菜单二</div>
              <FormItem label="背景图：">
                <div style="display: flex">
                  <div>
                    <Upload
                      ref="upload"
                      :show-upload-list="false"
                      :format="['jpg', 'jpeg', 'png']"
                      :max-size="2048"
                      :headers="header"
                      :on-format-error="handleFormatError"
                      :on-success="uploadImgSuccessdiv2"
                      :on-progress="handleProgress"
                      multiple
                      :action="uploads"
                    >
                    <div class="uplads_box">
                      <div style="text-align: center">
                        <div style="margin-top: -10px">
                          <Icon
                            style="color: #9ea6b9"
                            size="70"
                            type="ios-add"
                          />
                        </div>
                        <div
                          class="font14"
                          style="margin-top: -10px; color: #9ca7b9"
                        >
                          点击上传
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            color: #9ca7b9;
                            margin-top: -15px;
                          "
                        >
                          （342*90）
                        </div>
                      </div>
                    </div>
                    </Upload>
                  </div>
                  <div v-if="template_div.menu2.bgImg" class="img_view_Box">
                    <img :src="template_div.menu2.bgImg" alt="" />
                  </div>
                </div>
              </FormItem>
              <FormItem label="文字颜色：">
                <ColorPicker v-model="template_div.menu2.textColor" />
              </FormItem>
              <FormItem label="跳转路径：">
                <Select
                  v-model="template_div.menu2.pathValue"
                  style="width: 200px"
                >
                  <Option
                    v-for="item in pathList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="主标题：">
                <Input
                  v-model="template_div.menu2.text1"
                  placeholder="请输入带单文字一"
                ></Input>
              </FormItem>
              <FormItem label="副标题：">
                <Input
                  v-model="template_div.menu2.text2"
                  placeholder="请输入带单文字二"
                ></Input>
              </FormItem>

              <div class="menu_text">菜单三</div>
              <FormItem label="背景图：">
                <div style="display: flex">
                  <div>
                    <Upload
                      ref="upload"
                      :show-upload-list="false"
                      :format="['jpg', 'jpeg', 'png']"
                      :max-size="2048"
                      :headers="header"
                      :on-format-error="handleFormatError"
                      :on-success="uploadImgSuccessdiv3"
                      :on-progress="handleProgress"
                      multiple
                      :action="uploads"
                    >
                    <div class="uplads_box">
                      <div style="text-align: center">
                        <div style="margin-top: -10px">
                          <Icon
                            style="color: #9ea6b9"
                            size="70"
                            type="ios-add"
                          />
                        </div>
                        <div
                          class="font14"
                          style="margin-top: -10px; color: #9ca7b9"
                        >
                          点击上传
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            color: #9ca7b9;
                            margin-top: -15px;
                          "
                        >
                          （342*90）
                        </div>
                      </div>
                    </div>
                    </Upload>
                  </div>
                  <div v-if="template_div.menu3.bgImg" class="img_view_Box">
                    <img :src="template_div.menu3.bgImg" alt="" />
                  </div>
                </div>
              </FormItem>
              <FormItem label="文字颜色：">
                <ColorPicker v-model="template_div.menu3.textColor" />
              </FormItem>
              <FormItem label="跳转路径：">
                <Select
                  v-model="template_div.menu3.pathValue"
                  style="width: 200px"
                >
                  <Option
                    v-for="item in pathList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="主标题：">
                <Input
                  v-model="template_div.menu3.text1"
                  placeholder="请输入带单文字一"
                ></Input>
              </FormItem>
              <FormItem label="副标题：">
                <Input
                  v-model="template_div.menu3.text2"
                  placeholder="请输入带单文字二"
                ></Input>
              </FormItem>
            </span>
          </Form>
        </div>
        <div slot="footer">
          <Button class="btn" @click="divok">确定</Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import {
  getHomeTemplate,
  getStorePlugin,
  templateLs,
  getStoreDiy1,
  storeDiy,
} from "@/api/setting";
import { getStoreDiy } from "@/api/diy";
import config from "@/config";
import { getCookies } from "@/utils/helper";
import PhoneView from "./phone/phoneView";
import Shopdecoration from "./shopdecoration";
export default {
  components: { PhoneView, Shopdecoration, },
  data() {
    return {
        header: { "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token") },
        uploads: config.apiUrl + "/storeapi/data/uploads",
      formData: {
        mini_mode: 1,
        home_mode: 1,
        applet_template_id: 1,
        content: "",
        news_bg_color: "",
        news_font_color: "",
      },
      patternList: [
        { value: 1, label: "扫码点餐" },
        { value: 2, label: "会员卡" },
      ],
      pathList: [
        { value: 1, label: "积分商城" },
        { value: 2, label: "外卖选购" },
        { value: 3, label: "自取选购" },
      ],
      classdata: [],
      diy_plugin: null,
      templateLsPic: "",
      Divmodal: false,
      assembly: false,
      RefreshStatus: true,
      template_div: {
        bgColor: "",
        menu1: {
          textColor: "",
          bgImg: "",
          text1: "",
          text2: "",
          pathValue: "",
        },
        menu2: {
          textColor: "",
          bgImg: "",
          text1: "",
          text2: "",
          pathValue: "",
        },
        menu3: {
          textColor: "",
          bgImg: "",
          text1: "",
          text2: "",
          pathValue: "",
        },
      },
      bgColor: "",
      myArray: [],
    };
  },
  created() {
    this.RefreshStatus = false;
    this.getDiyContent();
    this.getStoreDiy();
    this.classmuban();
    this.getStorePlugin();
    this.templateLs();
  },
  methods: {
    // 获取店铺装修配置
    getStoreDiy() {
      getStoreDiy1().then((res) => {
        this.formData = res.data;
      });
    },
    // 获取DIY模板内容
    getDiyContent() {
      getStoreDiy().then((res) => {
        if (res.data.div_content.length != 0) {
          this.bgColor = JSON.parse(
            JSON.stringify(res.data.div_content.bgColor)
          );
          this.myArray = JSON.parse(
            JSON.stringify(res.data.div_content.myArray)
          );
        }
        this.RefreshStatus = true;
      });
    },
    // 模式列表
    templateLs() {
      templateLs().then((res) => {
        this.templateLsPic = res.data[1].template_img;
      });
    },
    // 跳转DIY编辑
    linkDIY(e) {
      this.assembly = e;
    },
    // 打开模板弹框
    editdivBtn(e) {
      if (e.id == 3) {
        this.template_div = JSON.parse(
          JSON.stringify(this.formData.template_div)
        );
      }
      this.Divmodal = true;
    },
    // 模板弹框确定按钮
    divok() {
      if (this.formData.applet_template_id == 3) {
        this.classdata[2].template_div = this.template_div;
        this.formData.template_div = this.template_div;
      }
      this.Divmodal = false;
    },
    // 保存配置按钮
    subBtn() {
      storeDiy(this.formData)
        .then((res) => {
          this.$Message.success(res.msg);
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    // 模板弹框取消按钮
    divcancel() {
      (this.template_div = {
        bgColor: "",
        menu1: { textColor: "", bgImg: "", text1: "", text2: "" },
        menu2: { textColor: "", bgImg: "", text1: "", text2: "" },
        menu3: { textColor: "", bgImg: "", text1: "", text2: "" },
      }),
        (this.Divmodal = false);
    },
    // 获取模板样式
    classmuban() {
      getHomeTemplate().then((res) => {
        res.data[0].name = "首页1";
        res.data[1].name = "首页2";
        res.data[2].name = "首页3";
        this.classdata = res.data;
      });
    },
    // 获取diy权限
    getStorePlugin() {
      getStorePlugin().then((res) => {
        this.diy_plugin = res.data.diy_plugin;
      });
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccessdiv1(e) {
      this.template_div.menu1.bgImg = e.data.path;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    uploadImgSuccessdiv2(e) {
      this.template_div.menu2.bgImg = e.data.path;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    uploadImgSuccessdiv3(e) {
      this.template_div.menu3.bgImg = e.data.path;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    // 组件返回
    backview(e) {
      this.RefreshStatus = false;
      this.getDiyContent();
      this.assembly = e;
    },
    // 返回
    backBtn() {
      this.$emit("backview", 0);
    },
  },
};
</script>

<style scoped>
.back_box {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 20px;
}
.content_box {
  position: relative;
  width: 100%;
  height: 78vh;
  /* overflow: auto; */
  margin-top: 20px;
  background: #fff;
  border-radius: 12px;
  /* padding: 30px; */
}
.formContent {
  padding-top: 20px;
  height: 72vh;
  overflow: auto;
}
.class_img_box {
  position: relative;
  margin-top: 10px;
  width: 300px;
  border: 2px solid #ff761d;
}
.class_img_box img {
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.class_img_box .view_box:hover {
  opacity: 1;
  cursor: pointer;
}
.view_box {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  /* background: #000; */
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.model_box {
  max-height: 500px;
  overflow: auto;
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
.img_view_Box {
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-left: 20px;
  overflow: hidden;
}
.img_view_Box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.menu_text {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
}
.textar {
  border: 1px solid #dcdee2;
  outline: none;
  line-height: 20px;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}
</style>
<style>
.ivu-radio-checked .ivu-radio-inner {
  border-color: #ff761d;
}
.ivu-radio-inner:after {
  background-color: #ff761d;
}
</style>